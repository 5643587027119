<template>
  <div class="alpha">
    <p class="text-1">
      The Office of Sheikh Hamdan bin Mohammed bin Rashid Al Maktoum, the Crown Prince of Dubai, is dedicated to
      upholding and advancing the visionary legacy of Dubai’s leadership. Committed to fostering innovation, economic
      growth, and cultural prosperity, this office works tirelessly to inspire and empower the people of Dubai and
      the broader UAE. Through civic engagement and public initiatives, the Office of Sheikh Hamdan strives to build
      a sustainable future, ensuring that Dubai remains a global hub for progress, excellence, and opportunity. Sheikh
      Hamdan continues to champion the values of safety, prosperity, and progress for all citizens, while maintaining
      Dubai's status as a beacon of development and opportunity in the region.
    </p>
  </div>
</template>

<script>
export default {
  name: "HomeSection3"
}
</script>

<style scoped>
.alpha{
  margin-top: 5%;
  margin-bottom: 5%;
}
.text-1{
  width: 70%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  line-height: 30px;
}

@media (max-width: 990px) {

}

@media (max-width: 700px) {
  .text-1{
    font-size: 16px;
    line-height: 25px;
  }
}

@media (max-width: 500px) {

}
</style>